import React, { useEffect } from "react";
import { useUserContext } from "../routes/Contextlib";
import { User } from "../@types/user";
import EncryptDecryptService from "../services/EncryptDecryptService";
import {
  fetchConfigData,
  fetchUserPermissions,
  getusers,
} from "../routes/ApiEndPoints";
import { APICALL } from "../services/ApiServices";
import CommonShipmentServices from "../services/CommonShipmentServices";

const Login: React.FC = () => {
  const { user, setUser } = useUserContext();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  token && window.localStorage.setItem("token", token);

  const setDateFormatOnLogin = async (id: number) => {
    let date = 'dd-MM-yyyy';
    if (id) {
      let postData = {
        method: 'POST',
        data: {
          type: 'model',
          name: 'WarehouseLocation',
          id: id
        }
      };
      const response = await APICALL.service(fetchConfigData, 'POST', postData, true);
      if (response.status === 200)
        date = response?.data?.date_format ?? 'dd-MM-yyyy';
    }
    window.localStorage.setItem("date_format", date);
  };

  if (token) {
    let data = JSON.parse(EncryptDecryptService.decryptData(token));
    setDateFormatOnLogin(data.warehouse_location);
  }

  useEffect(() => {
    prepareUserData();
  }, []);

  const prepareUserData = async () => {
    const encrypted = localStorage.getItem("token");
    if (encrypted) {
      let userData: User = encrypted
        ? JSON.parse(EncryptDecryptService.decryptData(encrypted))
        : null;
      if (userData?.user_id) {
        const permissions: any = await fetchPermissions(userData?.user_id);
        const userRoles: any = await updateUserRoles(userData?.user_id);
        userData.permissions = permissions;
        userData.roles = userRoles[0];
        userData.trackTime = userRoles[1];
        userData.date_format =
          localStorage.getItem("date_format") ?? "dd-MM-yyyy";
        setUser(userData);
        window.localStorage.setItem("user_id", userData.user_id.toString());
        const url = new URL(window.location.href);
        url.searchParams.delete("token");
        // window.history.pushState(null, "", url.href);
        window.history.replaceState(null, "", url.href);
      } else {
        window.location.href =
          process.env.REACT_APP_B_IDENTITY_MANAGER_URL ?? "";
      }
    } else {
      window.location.href = (process.env.REACT_APP_B_IDENTITY_MANAGER_URL + "token") ? (process.env.REACT_APP_B_IDENTITY_MANAGER_URL + "token") : "";
    }
    const bp_id = localStorage.getItem("bp_id");
    if (bp_id != null) {
      await CommonShipmentServices.setBpDetails(bp_id);
    }
  };

  const fetchPermissions = async (id: any) => {
    try {
      const postData = {
        method: "POST",
        data: { user_id: id },
      };

      const response = await APICALL.service(
        fetchUserPermissions,
        "POST",
        postData,
        true
      );
      return response?.permissions;
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserRoles = async (user_id: any) => {
    try {
      if (user_id != null) {
        const postData = {
          method: "POST",
          data: { userid: user_id },
        };

        const response = await APICALL.service(
          getusers,
          "POST",
          postData,
          true
        );
        const roles = response?.data[0].roles.map((item: any) => {
          return item.title;
        });
        const trackTime = response?.data[0].roles.map((item: any) => {
          return item.trackTime;
        });

        return [roles, trackTime];
      }
    } catch (error) {
      console.log(error);
    }
  };

  return null;
};

export default Login;
